import AgentSessionService, { DEFAULT_AGENT_CONTEXT } from '@root/agents.joinroot.com/src/services/agent-session-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import { RootError } from '@root-common/root-errors';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';

const AgentContext = React.createContext();
const AgentDispatchContext = React.createContext();

function agentReducer(state, action) {
  switch (action.type) {
  case 'set': {
    return action.payload;
  }
  case 'clear': {
    return {};
  }
  default: {
    throw new RootError({
      message: `Unhandled action type: ${action.type}`,
      name: 'AgentReducerError',
      fingerprint: ['AgentReducerUnhandledActionType'],
    });
  }
  }
}

function AgentProvider({ children, initialAgent = AgentSessionService.getCurrentAgentContext() }) {
  const [state, dispatch] = React.useReducer(agentReducer, initialAgent);

  useEffect(() => {
    AgentSessionService.setCurrentAgentContext(state);

    const {
      agency,
      email,
      id,
    } = state ?? DEFAULT_AGENT_CONTEXT;

    datadogRum.setUser({
      agencyId: agency?.id,
      agencyName: agency?.name,
      agencyGroup: agency?.group,
      email,
      id,
    });
  }, [state]);

  return (
    <AgentContext.Provider value={state}>
      <AgentDispatchContext.Provider value={dispatch}>
        {children}
      </AgentDispatchContext.Provider>
    </AgentContext.Provider>
  );
}

function useAgentState() {
  const context = React.useContext(AgentContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useAgentState must be used within AgentProvider',
      name: 'AgentProviderError',
    });
  }

  return context;
}

function useAgentDispatch() {
  const context = React.useContext(AgentDispatchContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useAgentDispatch must be used within AgentProvider',
      name: 'AgentProviderError',
    });
  }

  return context;
}

AgentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialAgent: PropTypes.object,
};

export {
  AgentProvider,
  useAgentState,
  useAgentDispatch,
};

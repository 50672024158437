import ServerUtils from '@root/core/src/api/server-utils';

export async function agentPasswordReset(params) {
  const { data } = await ServerUtils.unauthenticatedPatch(
    '/agent_api/password_reset',
    {
      body: {
        ...params,
      },
      expectedResponse: [200],
      expectedErrorResponses: [401, 422],
    });

  return data;
}

export async function sendForgotPassword(params) {
  const { data } = await ServerUtils.unauthenticatedPost(
    '/agent_api/password_reset',
    {
      body: {
        ...params,
      },
      expectedResponse: [200, 401],
      expectedErrorResponses: [422],
    });

  return data;
}

export async function configureAuthenticator(params) {
  const { data } = await ServerUtils.unauthenticatedPost(
    '/agent_api/sessions/configure_authenticator',
    {
      body: {
        ...params,
      },
      expectedResponse: [201],
      expectedErrorResponses: [401],
    });

  return data;
}

export async function validateSessionToken() {
  const { data } = await ServerUtils.authenticatedGet(
    '/agent_api/sessions/validate_token',
    {
      expectedResponse: [200, 401],
    }
  );

  return data;
}

export async function expireSessionToken() {
  const { data } = await ServerUtils.authenticatedPost(
    '/agent_api/sessions/expire_session',
    {
      expectedResponse: [200, 401],
    }
  );

  return data;
}

export async function sessionContext() {
  const { data } = await ServerUtils.authenticatedGet(
    '/agent_api/sessions/session_context',
    {
      expectedResponse: [200, 401],
    }
  );

  return data;
}

export async function loginWithEmail(params) {
  const { data } = await ServerUtils.unauthenticatedPost(
    '/agent_api/sessions',
    {
      body: {
        ...params,
      },
      expectedResponse: [200, 201, 400],
      expectedErrorResponses: [401],
    }
  );

  return data;
}

export async function sendOtpEmail(params) {
  return await ServerUtils.unauthenticatedPost(
    '/agent_api/sessions/send_otp_email',
    {
      body: {
        ...params,
      },
      expectedResponse: [200],
      expectedErrorResponses: [401, 422],
    }
  );
}

export async function validateOtp(params) {
  const { data } = await ServerUtils.unauthenticatedPost(
    '/agent_api/sessions/validate_otp',
    {
      body: {
        ...params,
      },
      expectedResponse: [201],
      expectedErrorResponses: [400, 401, 422],
    }
  );

  return data;
}

export async function getEstimatedQuote(estimated_quote_id) {
  const { data } = await ServerUtils.authenticatedGet(
    `/agent_api/cp_api_proxy/v3/quoting/quote/${estimated_quote_id}`,
    {
      expectedResponse: [200, 401],
    }
  );

  return data;
}

export async function patchEstimatedQuote(estimated_quote_id, params) {
  const { data } = await ServerUtils.authenticatedPatch(
    `/agent_api/cp_api_proxy/v3/quoting/quote/${estimated_quote_id}`,
    {
      body: {
        ...params,
      },
      expectedResponse: [200, 401, 425],
    }
  );

  return data;
}

export async function existingUser(email) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${encodeURIComponent(email)}/exists`, {
    expectedResponse: [200, 401],
  });

  return data;
}

export async function findUser(email) {
  const { data } = await ServerUtils.authenticatedPost('/agent_api/users/find', {
    body: {
      email,
    },
    expectedResponse: [200, 204, 401],
  });

  return data;
}
export async function updateUserPhoneNumber(userId, phoneNumber) {
  const { data } = await ServerUtils.authenticatedPost(`/agent_api/users/${userId}/update_phone_number`, {
    body: {
      phoneNumber,
    },
    expectedResponse: [200, 422, 401],
  });

  return data;
}

export async function updateUserFullName(userId, firstName, lastName) {
  const { data } = await ServerUtils.authenticatedPost(`/agent_api/users/${userId}/update_full_name`, {
    body: {
      firstName,
      lastName,
    },
    expectedResponse: [200, 422, 401],
  });

  return data;
}

export async function triageToClassic(userId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/triage_to_classic`, {
    expectedResponse: [200, 401],
  });

  return data;
}

export async function classicOnlyMarketCheck(market) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/markets/${market.toUpperCase()}/classic_only`, {
    expectedResponse: [200, 401],
  });

  return data;
}

export async function allowListedMarkets() {
  const { data } = await ServerUtils.authenticatedGet('/agent_api/markets/allow_listed_markets', {
    expectedResponse: [200, 401],
  });

  return data;
}

export async function createUser(userParams) {
  const { data } = await ServerUtils.authenticatedPost('/agent_api/users', {
    body: userParams,
    expectedResponse: [201, 400, 409, 401],
  });

  return data;
}

export async function fetchProfileRules(market, userId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/markets/${market}/rules`, {
    query: {
      userId,
    },
  });

  return data;
}

export async function sendAppLink(userId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/download_app`, {
    expectedResponse: [200, 401],
  });

  return data;
}

export async function sendTestDriveLinkSms(userId) {
  const { data } = await ServerUtils.authenticatedPost(`/agent_api/users/${userId}/send_test_drive_link`, {
    expectedResponse: [200, 401],
  });

  return data;
}

export async function triggerPrefill(userId, prefillParams) {
  const { data } = await ServerUtils.authenticatedPost(`/agent_api/users/${userId}/prefills`, {
    body: prefillParams,
    expectedResponse: [201, 401],
    expectedErrorResponses: [400],
  });

  return data;
}

export async function fetchPrefill(userId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/prefills`, {
    expectedResponse: [200, 204, 400, 401],
  });

  return data;
}

export async function triggerQuote(userId, params) {
  const { data } = await ServerUtils.authenticatedPost(`/agent_api/users/${userId}/quotes`, {
    body: params,
    expectedResponse: [201, 401],
    expectedErrorResponses: [400, 422],
  });

  return data;
}

export async function fetchQuote(userId, ratingRequestId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/quotes?rating_request_id=${ratingRequestId}`, {
    expectedResponse: [200, 204, 400, 401],
  });

  return data;
}

export async function fetchQuoteCoverages(userId, ratingRequestId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/quote_coverages?rating_request_id=${ratingRequestId}`, {
    expectedResponse: [200, 204, 400, 401],
  });
  return data;
}

export async function fetchQuotingRules(userId, market) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/quoting_rules`, {
    query: {
      market,
    },
  });

  return data;
}

export async function createCustomQuote(userId, params) {
  const { data } = await ServerUtils.authenticatedPost(`/agent_api/users/${userId}/quotes/custom`, {
    body: params,
    expectedResponse: [201, 400, 422, 401],
  });

  return data;
}

export async function emailQuoteToUser(userId, quoteId, billingCycle) {
  return await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/quotes/${quoteId}/email_to_user`, {
    query: { invoicePeriod: billingCycle },
    expectedResponse: [200, 401],
  });
}

export async function acceptConsent(userId) {
  return await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/accept_consent`, {
    expectedResponse: [200, 401],
  });
}

export async function validateLicense(licenseParams) {
  const { data } = await ServerUtils.authenticatedPost('/agent_api/users/validate_license', {
    body: licenseParams,
    expectedResponse: [200, 401],
    expectedErrorResponses: 422,
  });

  return data;
}

export async function enableOneProduct(userId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/users/${userId}/enable_one_product`, {
    expectedResponse: [200, 401],
    expectedErrorResponses: 422,
  });

  return data;
}

export async function resetMFA(email) {
  const { data, rawResponse } = await ServerUtils.authenticatedPost('/agent_api/reset_mfa_for_agent', {
    body: { email },
    expectedResponse: [200, 204],
    expectedErrorResponses: 401,
  });

  return {
    data,
    rawResponse,
  };
}

export async function createOrUpdateHotLeadRecord(userId, agentId, active, followUpAppointment) {
  const { data, rawResponse } = await ServerUtils.authenticatedPatch('/agent_api/agent_hot_leads/create_or_update', {
    body: {
      userId,
      agentId,
      active,
      followUpAppointment,
    },
    expectedResponse: [200, 204, 401],
    expectedErrorResponses: [422],
  });

  return {
    data,
    rawResponse,
  };
}

export async function fetchRatingVariableDifference(ratingRequestId, userId) {
  const { data } = await ServerUtils.authenticatedGet(`/agent_api/rating_variable_difference?rating_request_id=${ratingRequestId}&user_id=${userId}`, {
    expectedResponse: [200, 401],
  });

  return data;
}

import AuthService from '@root/core/src/services/auth-service';
import { useState } from '@root/vendor/react';

export default function useAccessToken() {
  const retrieveToken = () => {
    AuthService.getAccessToken();
  };

  const retrieveRememberDeviceToken = () => {
    AuthService.getRememberDeviceToken();
  };

  const [getAccessTokenState, setAccessTokenState] = useState(retrieveToken());
  const [getRememberDeviceToken, setRememberDeviceToken] = useState(retrieveRememberDeviceToken());

  const saveToken = (token) => {
    AuthService.setAccessToken(token);
    setAccessTokenState(token);
  };

  const saveRememberDeviceToken = (token) => {
    AuthService.setRememberDeviceToken(token);
    setRememberDeviceToken(token);
  };

  return {
    setAccessToken: saveToken,
    accessToken: getAccessTokenState,
    setRememberDeviceToken: saveRememberDeviceToken,
    rememberDeviceToken: getRememberDeviceToken,
  };
}
